/*
 * *dev ve asm için ayrı build paramları eklenecek.**/

// const IS_LOCAL = process.env.NODE_ENV !== 'production';
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! IS_LOCAL 
let IS_LOCAL = false;

const API_BASE_URL = IS_LOCAL ?
  // 'http://localhost:8896/' :
  'https://organization-api.wisdomera.io/' :
  'https://organization-api.wisdomera.io/';

const API_WMANAGER_URL = IS_LOCAL ?
  // 'http://localhost:8897/' :
  'https://manager-api.wisdomera.io/' :
  'https://manager-api.wisdomera.io/';

const API_WMAIL_URL = IS_LOCAL ?
  // 'http://localhost:7171/' :
  'https://mail-api.wisdomera.io/' :
  'https://mail-api.wisdomera.io/';

const WORGANIZATION_FRONTEND_URL = IS_LOCAL ?
  'http://localhost:8088/' :
  // 'https://worganization.mlpcare.com/' :
  // 'https://organization.wisdomera.io/' :
  'https://organization.wisdomera.io/';

//  : 'https://whs-api.wisdomera.io/';
//  : 'http://10.200.2.2:8890/';

const CLIENT_ID = 'wisdomhs';
const CLIENT_SECRET = 'wisdomera';
const GRANT_TYPE = 'password';

const WDMR_URL = IS_LOCAL ?
  // 'http://localhost:8077/' :
  'https://wdmr.wisdomera.io/' :
  'https://wdmr.wisdomera.io/';


const WDMR_LINKS = {
  'record': WDMR_URL + '#/wdmr-record?',
  'show': WDMR_URL + '#/wdmr-show?'
};

/*
const API_BASE_URL = IS_LOCAL
  ? 'http://localhost:8888/'
  : 'https://api.wisdomera.io/';

const CLIENT_ID = 'wisdomhs';
const CLIENT_SECRET = 'wisdomera';
const GRANT_TYPE = 'password';
*/

export {
  WORGANIZATION_FRONTEND_URL,
  API_BASE_URL,
  API_WMANAGER_URL,
  API_WMAIL_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  GRANT_TYPE,
  IS_LOCAL,
  WDMR_LINKS
};

